
import { useAppStore } from '@/store/app'
import Vue from 'vue'
import { Role } from '@/types/user'

export default Vue.extend({
  name: 'CategoriesLookup',
  props: {
    role: {
      default: 'basic' as Role
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    async role () {
      console.log(this.role)
    }
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      categories: new Array<any>()
    }
  },
  async mounted () {
    this.fetch()
  },
  methods: {
    filterData (item: any, queryText: string) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase())
      )
    },
    changeValue (value: string[]) {
      console.log(value)
      this.$emit('update:modelValue', JSON.parse(JSON.stringify(value)))
    },
    async fetch () {
      const user = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${user}/categories`)
      const categories = await res.json()

      this.categories = categories
    }
  }
})
