
import PdfViewer from '@/components/pdf-viewer.vue'
import DocumentIndicator from '@/components/request-signature/document-indicator.vue'
import { useAppStore } from '@/store/app'
import { Document } from '@/types/document'
import { getInitials } from '@/utils/name'
import Vue from 'vue'

type QuickView = 'completed' | 'waiting' | 'revoked'

export default Vue.extend({
  name: 'Documents',
  components: {
    DocumentIndicator,
    PdfViewer
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      headers: [
        { text: 'Template', value: 'title' },
        { text: 'Requester', value: 'requester' },
        { text: 'Last change', value: 'timestamp' },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      documents: new Array<Document>(),
      activeView: '' as QuickView,
      showInspectDialog: false,
      toView: {} as { name: string; url: string }
    }
  },
  async mounted () {
    this.fetch()
  },
  methods: {
    getInitials (name: string) {
      return getInitials(name)
    },
    async fetch () {
      this.loading = true
      const client = this.app.selectedClient?.id ?? ''
      const res = await fetch(`${process.env.VUE_APP_API_URL}/${client}/documents`)
      const documents = await res.json()

      this.documents = documents
        .map((document: {signed?: number; revoked?: number; createdAt: number;}) => ({
          ...document,
          status: document.signed
            ? 'signed'
            : document.revoked
              ? 'revoked'
              : 'waiting',
          timestamp: (document.signed || undefined) ?? (document.revoked || undefined) ?? document.createdAt ?? Date.now()
        }))
        .sort((r1: {timestamp: number}, r2: {timestamp: number}) => (r1.timestamp < r2.timestamp) ? 1 : (r1.timestamp > r2.timestamp) ? -1 : 0)
      this.loading = false
    },
    actionTime (timestamp: string) {
      if (!timestamp) {
        return undefined
      }

      const actionTimestamp = Number(timestamp)
      const utcDate = new Date(actionTimestamp)

      const time = utcDate.toLocaleTimeString().split(':')

      return `${utcDate.toLocaleDateString()} ${time[0]}:${time[1]}`
    },
    filterOnlyCapsText (value: any, search: string, item: Document) {
      return value &&
        search &&
        item &&
        (
          `${value ?? ''}`.includes(search) ||
          item.signer.email.includes(search) ||
          item.signer.name.includes(search) ||
          item.signer.lastname.includes(search)
        )
    },
    closeDialog () {
      this.showInspectDialog = false
      this.toView = {} as { name: string; url: string }
    },
    viewDocument (document: { name: string; url: string }) {
      this.toView = document
      this.showInspectDialog = true
    }
  }
})
