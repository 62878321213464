
import Vue from 'vue'
import Theme from '../components/theme.vue'
import UserManagement from '../components/user-management/user-management.vue'
import Templates from '../components/templates.vue'

type Settings = 'theme' | 'users' | 'templates'

export default Vue.extend({
  name: 'Settings',
  components: {
    UserManagement,
    Theme,
    Templates
  },
  data () {
    return {
      activeSetting: 'users' as Settings
    }
  }
})
