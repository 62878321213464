
import { primary } from '@/constants'
import { DocumentStatus } from '@/types/document'
import { mdiFileDocumentArrowRightOutline, mdiFileDocumentCheckOutline, mdiFileDocumentOutline, mdiFileDocumentRemoveOutline } from '@mdi/js'
import Vue, { PropOptions } from 'vue'
import xIcon from '../common/x-icon.vue'

const statuses: Record<DocumentStatus, { background: string; color: string; }> = {
  signed: {
    background: '#eef6ee',
    color: '#55a365'
  },
  waiting: {
    background: '#fff8e6',
    color: '#ffb800'
  },
  revoked: {
    background: '#fff0f0',
    color: '#ef5a5a'
  },
  unset: {
    background: '#e4dbff',
    color: primary
  }
}

export default Vue.extend({
  name: 'DocumentIndicator',
  components: { xIcon },
  props: {
    status: {
      type: String,
      required: true
    } as PropOptions<'signed' | 'revoked' | 'waiting'>
  },
  data () {
    return {
      total: 0,
      mdi: {
        waiting: mdiFileDocumentArrowRightOutline,
        signed: mdiFileDocumentCheckOutline,
        revoked: mdiFileDocumentRemoveOutline,
        unset: mdiFileDocumentOutline
      }
    }
  },
  computed: {
    color () {
      const status = this.status
      return statuses[status]?.color
    },
    background () {
      const status = this.status
      return statuses[status]?.background
    }
  }
})
