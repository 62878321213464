
import Vue from 'vue'
import FinishedSVG from '../components/svg/finished.vue'
import { useAppStore } from '@/store/app'

export default Vue.extend({
  name: 'Finished',
  components: {
    FinishedSVG
  },
  setup () {
    const app = useAppStore()

    return {
      app
    }
  },
  data () {
    return {
      color: ''
    }
  },
  beforeMount () {
    this.color = this.app.primaryColor
  }
})
